import React from "react"
import Header from "../../components/header"
import HeaderLeistung from "../../components/headerLeistung"
import LeistungBox from "../../components/boxLeistung"
import Footer from "../../components/footer"
import ContactBox from "../../components/boxContact"

import imgWartung01 from "../../assets/leistungen/wartung01.jpg"
import imgWartung02 from "../../assets/leistungen/wartung02.jpg"
import imgWartung03 from "../../assets/leistungen/wartung03.jpg"

export default () => (
  <div>
    <Header />
    <HeaderLeistung
      Title="Reparatur &amp; Wartung"
      Content="Die Dachrinne tropft, ein Dachziegel ist gerissen, der Schiefer am Kamin hängt schief. Kleine Baufälligkeiten können mit der Zeit große Schäden verursachen. Umso wichtiger ist es, die betroffenen Stellen überprüfen und fachmännisch reparieren zu lassen."
    />
    <LeistungBox
      Color="gray"
      Image={imgWartung01}
      IsImageLeft={true}
      Title="Wartungsarbeiten"
      Content="So wie Sie Ihr Auto regelmäßig zur Inspektion bringen, um die Lebensdauer zu verlängern, so empfehlen wir auch für Ihr Dach eine turnusmäßige Kontrolle. Schließlich ist ein Dach ständig der Witterung ausgesetzt und verdient Fürsorge."
    />
    <LeistungBox
      Color="white"
      Image={imgWartung02}
      IsImageLeft={false}
      Title="Dachreparaturen"
      Content="Wir kümmern uns schnell und professionell um Ihren Schaden am Dach – egal ob es sich dabei um eine undichte Stelle handelt oder um ein Problem mit Ihrem Steil- oder Flachdach. Eine zügige Reparatur verhindert oftmals kostspielige Folgeschäden."
    />
    <LeistungBox
      Color="white"
      Image={imgWartung03}
      IsImageLeft={true}
      Title="Dachrinnensäuberung"
      Content="Wenn Regenwasser in Dachrinnen nicht schnell genug abfließt, ist das eine Gefahr für Dachkonstruktion und Fassade. Der Grund für verstopfte Dachrinnen können Laub oder absandende Dachsteine sein. Wir säubern Ihre Dachrinne fachmännisch und unter Einsatz unserer Hubarbeitsbühne. Und wenn Sie Probleme mit Tauben haben, kümmern wir uns auch darum und befestigen im Zuge der Dachrinnenreinigung die Vogelabwehrgitter gleich mit."
    />
    <ContactBox isFoot="true" />
    <Footer />
  </div>
)
